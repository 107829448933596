import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { LayoutWrapper } from "../components/LayoutWrapper/LayoutWrapper"
import Layout from "../layouts/Layout/Layout"
import { fonts } from "../utils/fonts"
import ArticleAbstract from "../components/ArticleAbstract/ArticleAbstract"

const StyledImageWrapper = styled.figure`
  padding-bottom: 54%;
  position: relative;
`

const StyledImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
`

const StyledTitle = styled.h2`
  ${fonts.headingExtraSmall}
  margin-top: 80px;
`

const StyledContentWrapper = styled.div`
  margin-top: 50px;

  p {
    ${fonts.paragraph}
    + p {
      margin-top: 1em;
    }
  }
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: 40px;
`

const StyledMainContentWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  flex-grow: 1;
`

const StyledArticlesList = styled.div`
  width: calc(100% / 3);
  flex-shrink: 0;
  padding-left: 20px;
  padding-right: 20px;
`

const StyledListItem = styled.div`
  :not(last-child) {
    margin-bottom: 60px;
  }
`

const Article = ({ pageContext: { slug }, data: { article, articles } }) => {
  return (
    <Layout>
      <LayoutWrapper>
        <StyledWrapper>
          <StyledMainContentWrapper>
            <StyledImageWrapper>
              <StyledImage src={article.image.url} />
            </StyledImageWrapper>
            <StyledTitle>{article.title}</StyledTitle>
            <StyledContentWrapper
              dangerouslySetInnerHTML={{ __html: article.content }}
            />
          </StyledMainContentWrapper>

          <StyledArticlesList>
            {articles.edges.map(({ node }, index) => {
              if (index < 2) {
                return (
                  <StyledListItem>
                    <ArticleAbstract
                      imageUrl={node.image.url}
                      title={node.title}
                      excerpt={node.abstract}
                      url={node.slug}
                    />
                  </StyledListItem>
                )
              }
            })}
          </StyledArticlesList>
        </StyledWrapper>
      </LayoutWrapper>
    </Layout>
  )
}

export const query = graphql`
  query fetchArticle($slug: String) {
    article: datoCmsArticle(slug: { eq: $slug }) {
      id
      slug
      title
      content
      image {
        alt
        url
      }
    }
    articles: allDatoCmsArticle(sort: {fields: meta___createdAt, order: DESC}) {
      edges {
        node {
          slug
          title
          content
          abstract
          image {
            alt
            url
          }
        }
      }
    }
  }
`

export default Article
